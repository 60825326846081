import { faArrowLeft, faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";

function Header({ navigate, desc = undefined }) {
  const location = useLocation();
  return (
    <Grid
      display={"flex"}
      flexDirection={"row"}
      justifyContent={"space-between"}
      width={"100%"}
      padding={"10px"}
      borderBottom={"1px solid"}
      sx={{ backgroundColor: "#ccc" }}
    >
      <Typography variant="h4">
        {location.pathname.substring(1) +
          (desc !== undefined ? " ( " + desc + " ) " : "")}
      </Typography>

      <FontAwesomeIcon
        onClick={() => navigate("/")}
        cursor={"pointer"}
        color="red"
        icon={faHome}
        size="2x"
      />
    </Grid>
  );
}

export default Header;
