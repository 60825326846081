import { useContext, useEffect, useState } from "react";
import { dispatchStateContext, globalStateContext } from "../App";
import Chart from "../components/Chart";
import { routes } from "../services/API";
import { _GET } from "../services/Fetch";
import { useParams } from "react-router-dom";

function ConflictResolverStatistic() {
  const [data, setData] = useState();

  const useGlobalState = () => [
    useContext(globalStateContext),
    useContext(dispatchStateContext),
  ];
  const [state, dispatch] = useGlobalState();
  const params = useParams();

  useEffect(() => {
    dispatch({ loading: true });
    Promise.all([_GET(routes.getStats + params.jobId, state.token)]).then(
      (res) => {
        dispatch({ loading: false });
        if (res[0] != null) setData(res[0]);
      }
    );
  }, []);

  return (
    <>
      {data && data.length > 0 && (
        <Chart
          labels={data.map((e) => e.time)}
          stats={data.map((e) => e.count)}
        />
      )}
    </>
  );
}

export default ConflictResolverStatistic;
