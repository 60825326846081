import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { useState, useEffect, useContext } from "react";
import { _GET, _POST, _PUT } from "../../services/Fetch";
import { routes } from "../../services/API";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import { dispatchStateContext, globalStateContext } from "../../App";

function ClassesWithTransactionConflicts() {
  const [data, setData] = useState();

  const useGlobalState = () => [
    useContext(globalStateContext),
    useContext(dispatchStateContext),
  ];
  const [state, dispatch] = useGlobalState();

  useEffect(() => {
    dispatch({ loading: true });
    Promise.all([
      _GET(routes.classesWithTransactionConflicts, state.token),
    ]).then((res) => {
      dispatch({ loading: false });
      if (res[0] != null)
        setData(
          res[0].map((record) => {
            return {
              ...record,
              show: true,
            };
          })
        );
    });
  }, []);

  const navigate = useNavigate();

  const syncTransactionWithPassed = async (classId, userId, paid) => {
    dispatch({ loading: true });
    const res = await _POST(
      routes.syncTransactionWithPassed + classId + "/" + userId + "/" + paid,
      undefined,
      state.token
    );
    dispatch({ loading: false });
    if (res === null) return;

    dispatch({ showSuccess: true });
    setData(
      data.map((record) => {
        if (record.userId === userId && record.classId === classId)
          return {
            ...record,
            show: false,
          };

        return record;
      })
    );
  };

  const removeTransaction = async (transactionId) => {
    dispatch({ loading: true });
    const res = await _POST(
      routes.removeTransaction + transactionId,
      undefined,
      state.token
    );
    dispatch({ loading: false });
    if (res === null) return;

    dispatch({ showSuccess: true });
    setData(
      data.map((record) => {
        if (record.transactionId === transactionId)
          return {
            ...record,
            show: false,
          };

        return record;
      })
    );
  };

  return (
    <Grid display={"flex"} flexDirection={"column"}>
      <Header navigate={navigate} />
      {data && (
        <Typography textAlign={"center"} marginTop={"10px"} variant="h5">
          {"total count: " + data.length}
        </Typography>
      )}
      <Grid
        padding={"20px"}
        display={"flex"}
        flexDirection={"row"}
        gap={"50px"}
        rowGap={"20px"}
        flexWrap={"wrap"}
      >
        {data &&
          data.map((record, index) => {
            if (!record.show) return;
            return (
              <Card sx={{ minWidth: 275, maxWidth: 275 }} key={index}>
                <CardContent>
                  <Typography variant="h5" color="text.secondary" gutterBottom>
                    userName: {record.userName}
                  </Typography>
                  <Typography variant="h5" color="text.secondary" gutterBottom>
                    className: {record.className}
                  </Typography>
                  <Typography color="text.secondary" variant="body1">
                    amount: {record.amount}
                  </Typography>
                  <Typography color="text.secondary" variant="body1">
                    transaction refId: {record.refId}
                  </Typography>
                  <Typography color="text.secondary" variant="body1">
                    force by admin: {record.forceByAdmin ? "Yes" : "No"}
                  </Typography>
                  <Typography color="text.secondary" variant="body1">
                    payLink: {record.payLink ? "Yes" : "No"}
                  </Typography>
                  <Typography
                    sx={{
                      mb: 1.5,
                    }}
                    color="text.secondary"
                    variant="body1"
                  >
                    payAt: {record.payAt}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    onClick={() =>
                      dispatch({
                        showDialog: true,
                        dialogTitle:
                          "Force registry " +
                          record.userName +
                          " in " +
                          record.className,
                        dialogMsg: "Are you sure about it?",
                        dialogOnAccept: () =>
                          syncTransactionWithPassed(
                            record.classId,
                            record.userId,
                            record.amount.replaceAll(",", "")
                          ),
                      })
                    }
                    size="small"
                  >
                    Force registry in class
                  </Button>
                  <Button
                    onClick={() =>
                      dispatch({
                        showDialog: true,
                        dialogTitle: "Remove transaction",
                        dialogMsg: "Are you sure about it?",
                        dialogOnAccept: () =>
                          removeTransaction(record.transactionId),
                      })
                    }
                    size="small"
                  >
                    Remove transaction
                  </Button>
                </CardActions>
              </Card>
            );
          })}
      </Grid>
    </Grid>
  );
}

export default ClassesWithTransactionConflicts;
