import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import { dispatchStateContext, globalStateContext } from "../../App";
import { useContext } from "react";
import { _POST, _PUT } from "../../services/Fetch";
import { routes } from "../../services/API";

function DashboardCard({ conflict, navigate, data, setData }) {
  const useGlobalState = () => [
    useContext(globalStateContext),
    useContext(dispatchStateContext),
  ];

  const [state, dispatch] = useGlobalState();

  const autoResolve = async (jobId) => {
    dispatch({ loading: true });
    const res = await _POST(routes.autoResolve + jobId, undefined, state.token);
    dispatch({ loading: false });
    if (res === null) return;
    dispatch({ showSuccess: true });
  };

  const changeJobEnability = async (jobId) => {
    dispatch({ loading: true });
    const res = await _PUT(
      routes.changeJobEnability + jobId,
      undefined,
      state.token
    );
    dispatch({ loading: false });

    if (res === null) return;

    dispatch({ showSuccess: true });

    setData(
      data.map((record) => {
        if (record.jobId === jobId)
          return {
            ...record,
            isEnableInJobs: !record.isEnableInJobs,
          };
        return record;
      })
    );
  };

  const changeJobResolver = async (jobId, resolver) => {
    dispatch({ loading: true });
    const res = await _PUT(
      routes.changeJobResolver + jobId + "/" + resolver,
      undefined,
      state.token
    );
    dispatch({ loading: false });

    if (res === null || res?.status !== "ok") return;

    dispatch({ showSuccess: true });
    setData(
      data.map((record) => {
        if (record.jobId === jobId)
          return {
            ...record,
            selectedResolveStrategy: resolver,
          };
        return record;
      })
    );
  };

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {conflict.name}
        </Typography>

        <Typography sx={{ mb: 1.5 }} color="text.secondary" variant="h1">
          {conflict.count}
        </Typography>
      </CardContent>
      <CardActions>
        <Grid display={"flex"} flexDirection={"column"} alignItems={"center"}>
          <Grid display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <Button
              onClick={() => {
                navigate("/" + conflict.href);
              }}
              size="small"
            >
              More
            </Button>
            {conflict.jobId && (
              <>
                {/* <Button
                  onClick={() => {
                    dispatch({
                      showDialog: true,
                      dialogTitle:
                        "Auto resolve " +
                        conflict.name +
                        " with " +
                        conflict.selectedResolveStrategy +
                        " strategy",
                      dialogMsg: "Are you sure about it?",
                      dialogOnAccept: () => autoResolve(conflict.jobId),
                    });
                  }}
                  size="small"
                >
                  Auto Fix
                </Button> */}
                <Button
                  onClick={() =>
                    navigate("/conflict-resolver-stats/" + conflict.jobId)
                  }
                  size="small"
                >
                  Stats
                </Button>
              </>
            )}
          </Grid>
          {conflict.jobId && (
            <FormControlLabel
              control={
                <Switch
                  onClick={(event) => {
                    event.preventDefault();
                    dispatch({
                      showDialog: true,
                      dialogTitle:
                        "Make " +
                        conflict.name +
                        " in jobs " +
                        (event.target.checked ? "on" : "off"),
                      dialogMsg: "Are you sure about it?",
                      dialogOnAccept: () => changeJobEnability(conflict.jobId),
                    });
                  }}
                  size="small"
                  color="secondary"
                  checked={conflict.isEnableInJobs}
                />
              }
              label="Enability in jobs"
            />
          )}
          {conflict.isEnableInJobs && (
            <FormControl variant="standard" sx={{ m: 1, minWidth: 180 }}>
              <InputLabel id="demo-simple-select-standard-label">
                reolve strategies
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                label={"reolve strategies"}
                id="strategies"
                value={conflict.selectedResolveStrategy}
                onChange={(event) => {
                  dispatch({
                    showDialog: true,
                    dialogTitle: "Make " + event.target.value + " as resolver",
                    dialogMsg: "Are you sure about it?",
                    dialogOnAccept: () =>
                      changeJobResolver(conflict.jobId, event.target.value),
                  });

                  const newConflicts = data.map((e) => {
                    if (e.name === conflict.name) {
                      return {
                        ...conflict,
                        selectedResolveStrategy: event.target.value,
                      };
                    }
                    return e;
                  });
                  setData(newConflicts);
                }}
              >
                {conflict.resolveStrategies !== null &&
                  conflict.resolveStrategies.map((option, index) => {
                    return (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          )}
        </Grid>
      </CardActions>
    </Card>
  );
}

export default DashboardCard;
