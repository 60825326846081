import { LineChart } from "@mui/x-charts";

function Chart(props) {
  return (
    <LineChart
      xAxis={[
        {
          data: props.labels,
          scaleType: "band",
        },
      ]}
      series={[
        {
          data: props.stats,
        },
      ]}
      width={500}
      height={300}
    />
  );
}

export default Chart;
