import { Button, FormControl, Grid, Input, InputLabel } from "@mui/material";
import { dispatchStateContext, globalStateContext } from "../App";
import { useContext, useEffect, useState } from "react";
import { _POST } from "../services/Fetch";
import { routes } from "../services/API";

function Login() {
  const [data, setData] = useState({
    username: "",
    password: "",
  });

  const useGlobalState = () => [
    useContext(globalStateContext),
    useContext(dispatchStateContext),
  ];
  const [state, dispatch] = useGlobalState();

  useEffect(() => {
    const token = window.localStorage.token;
    if (token !== undefined) {
      window.location.href = "/dashboard";
    }
  }, []);

  return (
    <Grid
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      margin={"200px auto"}
      width={"300px"}
      alignItems={"center"}
      alignContent={"center"}
      gap={"20px"}
    >
      <FormControl sx={{ width: "100%" }}>
        <InputLabel id="demo-simple-select-label">Username</InputLabel>
        <Input
          onChange={(event) =>
            setData((prevValues) => ({
              ...prevValues,
              username: event.target.value,
            }))
          }
          label={"Username"}
        />
      </FormControl>

      <FormControl sx={{ width: "100%" }}>
        <InputLabel id="demo-simple-select-label">Password</InputLabel>
        <Input
          onChange={(event) =>
            setData((prevValues) => ({
              ...prevValues,
              password: event.target.value,
            }))
          }
          type="password"
          label={"Username"}
        />
      </FormControl>

      <Button
        onClick={async () => {
          if (data.username.length === 0 || data.password.length === 0) {
            dispatch({
              showError: true,
              errMsg: "Please enter username and password",
            });
            return;
          }
          dispatch({ loading: true });
          let res = await _POST(routes.signIn, data);
          dispatch({ loading: false });
          if (res !== null && res.status === "ok") {
            window.localStorage.token = res.token;
            window.location.href = "/";
          } else
            dispatch({
              showError: true,
              errMsg:
                res.msg === undefined
                  ? "Username or password is incorrect"
                  : res.msg,
            });
        }}
        sx={{ width: "300px", marginTop: "50px" }}
        variant="contained"
      >
        Login
      </Button>
    </Grid>
  );
}

export default Login;
