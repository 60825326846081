import { Grid, Link } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Home() {
  const navigate = useNavigate();

  useEffect(() => {
    const token = window.localStorage.getItem("token");
    if (token === undefined || token === null || token === "") {
      navigate("/login");
    }
  }, []);

  return (
    <Grid
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      margin={"200px auto"}
      width={"300px"}
      alignItems={"center"}
      alignContent={"center"}
      gap={"20px"}
    >
      <Link href="/dashboard">Dashboard</Link>
      <Link href="/transactions">Transactions</Link>
      <Link href="/dailyReport">Daily conflicts report</Link>
    </Grid>
  );
}

export default Home;
