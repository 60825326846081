export const routes = {
  getConflictsCount: "conflict/getConflictsCount",
  duplicateCurrTerms: "conflict/duplicateCurrTerms",
  getStats: "resolver_history/history/",
  dailyReport: "resolver_history/daily_report/",
  OSDConflicts: "conflict/OSDConflicts",
  duplicateUserMailRecords: "conflict/duplicateUserMailRecords",
  clearCache: "conflict/clearCache",
  conflictInClassesWithPassed: "conflict/conflictInClassesWithPassed",
  conflictInPassedWithClasses: "conflict/conflictInPassedWithClasses",
  passedWithTransactionConflicts: "conflict/passedWithTransactionConflicts",
  classesWithTransactionConflicts: "conflict/classesWithTransactionConflicts",
  addUserToClass: "resolver/addUserToClass/",
  addClassToPassed: "resolver/addClassToPassed/",
  removeUserFromClass: "resolver/removeUserFromClass/",
  removeClassFromPassed: "resolver/removeClassFromPassed/",
  checkMail: "resolver/checkMail",
  addUserToExam: "osd/admin/forceRegistry/",
  removeTransaction: "resolver/removeTransaction/",
  changeJobEnability: "resolver/changeJobEnability/",
  changeJobResolver: "resolver/changeJobResolver/",
  autoResolve: "resolver/autoResolve/",
  syncTransactionWithPassed: "resolver/syncTransactionWithPassed/",
  signIn: "user/login",
};
