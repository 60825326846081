import { _GET, _POST, _PUT } from "../../services/Fetch";
import { routes } from "../../services/API";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { dispatchStateContext, globalStateContext } from "../../App";
import { useContext } from "react";
import Header from "../../components/Header";
import DashboardCard from "./DashboardCard";

function Dashboard() {
  const useGlobalState = () => [
    useContext(globalStateContext),
    useContext(dispatchStateContext),
  ];
  const [state, dispatch] = useGlobalState();

  const [data, setData] = useState();
  const [mailAddress, setMailAddress] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({ loading: true });
    Promise.all([_GET(routes.getConflictsCount, state.token)]).then((res) => {
      dispatch({ loading: false });
      if (res[0] != null) setData(res[0]);
    });
  }, []);

  return (
    <>
      <Header desc={"all active jobs run at 2:30 AM"} navigate={navigate} />
      <Grid padding={"20px"} display={"flex"} flexDirection={"column"}>
        <Grid
          padding={"20px"}
          display={"flex"}
          flexDirection={"row"}
          gap={"50px"}
          rowGap={"20px"}
          flexWrap={"wrap"}
        >
          {data &&
            data.map((conflict, index) => {
              return (
                <DashboardCard
                  key={index}
                  data={data}
                  setData={setData}
                  navigate={navigate}
                  conflict={conflict}
                />
              );
            })}
        </Grid>
        <Grid
          display={"flex"}
          flexDirection={"row"}
          gap={"10px"}
          justifyContent={"center"}
        >
          <Grid alignItems={"end"} display={"flex"} gap={"20px"}>
            <Button
              onClick={async () => {
                await _POST(routes.clearCache, undefined, state.token);
              }}
              variant="contained"
            >
              Clear Cache
            </Button>
          </Grid>
          <Grid display={"flex"} flexDirection={"column"} gap={"10px"}>
            <Input
              onChange={(event) => {
                setMailAddress(event.target.value);
              }}
              name="mail"
              placeholder="Mail address"
              type="mail"
            />
            <Button
              onClick={async () => {
                if (mailAddress === undefined || mailAddress.length === 0) {
                  dispatch({
                    showError: true,
                    errMsg: "Please enter mail address",
                  });
                  return;
                }
                dispatch({ loading: true });
                const res = await _POST(
                  routes.checkMail + "?mailAddress=" + mailAddress,
                  undefined,
                  state.token
                );
                dispatch({ loading: false });
                if (res !== null) {
                  dispatch({ showSuccess: true });
                }
              }}
              variant="contained"
            >
              Check Mail
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Dashboard;
