import { LineChart } from "@mui/x-charts";
import { useCallback, useState } from "react";
import { _GET } from "../../services/Fetch";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import moment from "moment-jalaali";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";

function Transactions() {
  const [data, setData] = useState();
  const [month, setMonth] = useState("Farvardin");
  const [year, setYear] = useState(1402);
  const [day, setDay] = useState(1);
  const days = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
  ];
  const { innerWidth: width, innerHeight: height } = window;
  const [reportType, setReportType] = useState("monthly");

  const navigate = useNavigate();

  const fetch = useCallback(() => {
    const start = moment(
      year + "/" + month + "/" + (reportType === "monthly" ? "01" : day),
      "jYYYY/jM/jD"
    );

    const end = start.clone();
    end.add(1, reportType === "monthly" ? "month" : "day");

    const filterStr = reportType === "monthly" ? "DAILY" : "FIVE_MIN";

    Promise.all([
      _GET(
        "admin/moneyReport/countStats/" +
          filterStr +
          "/" +
          start.unix() * 1000 +
          "/" +
          end.unix() * 1000,
        undefined
      ),
    ]).then((res) => {
      if (res[0] != null) {
        if (reportType === "monthly") {
          setData(
            res[0].map((e) => {
              e.time = e.time.split("-")[0].trim().substring(5);
              return e;
            })
          );
        } else {
          setData(
            res[0].map((e) => {
              e.time = e.time.split("-")[1].trim();
              return e;
            })
          );
        }
      }
    });
  }, [month, reportType, day]);

  return (
    <>
      <Header desc={"Transactions"} navigate={navigate} />
      <Grid
        display={"flex"}
        flexDirection={"column"}
        gap={"10px"}
        padding={"20px"}
      >
        <Grid display={"flex"} flexDirection={"row"} gap={"20px"}>
          <FormControl>
            <InputLabel id="demo-simple-select-label">Report type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={reportType}
              label="Report type"
              onChange={(event) => {
                setReportType(event.target.value);
              }}
            >
              <MenuItem value={"monthly"}>monthly</MenuItem>
              <MenuItem value={"daily"}>daily</MenuItem>
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel id="demo-simple-select-year">Year</InputLabel>
            <Select
              labelId="demo-simple-select-year"
              id="demo-simple-year"
              value={year}
              label="Year"
              onChange={(event) => {
                setYear(event.target.value);
              }}
            >
              <MenuItem value={1400}>1400</MenuItem>
              <MenuItem value={1401}>1401</MenuItem>
              <MenuItem value={1402}>1402</MenuItem>
              <MenuItem value={1403}>1403</MenuItem>
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel id="demo-simple-select-label2">Month</InputLabel>
            <Select
              labelId="demo-simple-select-label2"
              id="demo-simple-select2"
              value={month}
              label="Month"
              onChange={(event) => {
                setMonth(event.target.value);
              }}
            >
              <MenuItem value={"01"}>Farvardin</MenuItem>
              <MenuItem value={"02"}>Ordibehesht</MenuItem>
              <MenuItem value={"03"}>Khordad</MenuItem>
              <MenuItem value={"04"}>Tir</MenuItem>
              <MenuItem value={"05"}>Mordad</MenuItem>
              <MenuItem value={"06"}>Shahrivar</MenuItem>
              <MenuItem value={"07"}>Mehr</MenuItem>
              <MenuItem value={"08"}>Aban</MenuItem>
              <MenuItem value={"09"}>Azar</MenuItem>
              <MenuItem value={"10"}>Dey</MenuItem>
              <MenuItem value={"11"}>Bahman</MenuItem>
              <MenuItem value={"12"}>Esfand</MenuItem>
            </Select>
          </FormControl>
          {reportType === "daily" && (
            <FormControl>
              <InputLabel id="demo-simple-select-label3">Day</InputLabel>
              <Select
                labelId="demo-simple-select-label3"
                id="demo-simple-select3"
                value={day}
                label="Day"
                onChange={(event) => {
                  setDay(event.target.value);
                }}
              >
                {days.map((e, index) => {
                  return (
                    <MenuItem key={index} value={e}>
                      {e}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}
          <Button onClick={() => fetch()} size="small">
            Search
          </Button>
        </Grid>
        {data && (
          <LineChart
            xAxis={[
              {
                data: data.map((e) => e.time),
                scaleType: "band",
              },
            ]}
            series={[
              {
                data: data.map((e) => e.counter),
              },
            ]}
            width={width - 100}
            height={height - 100}
          />
        )}
      </Grid>
    </>
  );
}

export default Transactions;
