import { useContext, useEffect, useState } from "react";
import Chart from "../../components/Chart";
import { dispatchStateContext, globalStateContext } from "../../App";
import { routes } from "../../services/API";
import { _GET } from "../../services/Fetch";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";

function DailyReport() {
  const [data, setData] = useState();
  const navigate = useNavigate();

  const [conflictType, setConflictType] = useState("currTermDuplicate");

  const useGlobalState = () => [
    useContext(globalStateContext),
    useContext(dispatchStateContext),
  ];
  const [state, dispatch] = useGlobalState();

  useEffect(() => {
    dispatch({ loading: true });
    Promise.all([_GET(routes.dailyReport + conflictType, state.token)]).then(
      (res) => {
        dispatch({ loading: false });
        if (res[0] != null) setData(res[0]);
      }
    );
  }, [conflictType]);

  return (
    <>
      <Header desc={"Daily conflicts stats"} navigate={navigate} />
      <Grid padding={"20px"}>
        <FormControl>
          <InputLabel id="demo-simple-select-label">Conflict name</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={conflictType}
            label="Conflict name"
            onChange={(event) => {
              setConflictType(event.target.value);
            }}
          >
            <MenuItem value={"currTermDuplicate"}>currTermDuplicate</MenuItem>
            <MenuItem value={"currClassDuplicate"}>currClassDuplicate</MenuItem>
            <MenuItem value={"conflictPassedWithClass"}>
              conflictPassedWithClass
            </MenuItem>
            <MenuItem value={"conflictInClassesWithPassed"}>
              conflictInClassesWithPassed
            </MenuItem>
            <MenuItem value={"currClassDuplicate"}>currClassDuplicate</MenuItem>
            <MenuItem value={"conflictInTransactionsWithClass"}>
              conflictInTransactionsWithClass
            </MenuItem>
            <MenuItem value={"conflictInTransactionsWithPassed"}>
              conflictInTransactionsWithPassed
            </MenuItem>
            <MenuItem value={"classesNotAddedToAdobe"}>
              classesNotAddedToAdobe
            </MenuItem>
            <MenuItem value={"OSDTransactionConflicts"}>
              OSDTransactionConflicts
            </MenuItem>
            <MenuItem value={"duplicateOSDRecords"}>
              duplicateOSDRecords
            </MenuItem>
            <MenuItem value={"duplicateUserMailRecords"}>
              duplicateUserMailRecords
            </MenuItem>
            <MenuItem value={"duplicateUserNIDRecords"}>
              duplicateUserNIDRecords
            </MenuItem>
          </Select>
        </FormControl>
        {data && data.length > 0 && (
          <Chart
            labels={data.map((e) => e.time)}
            stats={data.map((e) => e.count)}
          />
        )}
      </Grid>
    </>
  );
}

export default DailyReport;
